import { ActionTypes } from "./../contants/action-types";

const initialState = {
    primaryAccount : [],
    loading:true
}

export const primaryAccount = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.PRIMARY_ACCOUNT:
            return {
                ...state, 
                primaryAccount: payload, 
                loading:false
            };
        case ActionTypes.PRIMARY_ACCOUNT_ERROR:
        return{
            loading: false, 
            error: payload 
        }    
        default:
            return state;
    };
};