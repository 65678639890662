import React from 'react';
import bantransfer from "./../components/icons/banktransfer.png";

export default function BankTransfer() {
  return(
    <div className='Home_Icons'>
           <img alt="to Mobile" src={bantransfer} style={{width:"3em",height:"3em"}}/>
      Bank Transfer
    </div>
  )
}