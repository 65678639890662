import { ActionTypes } from "./../contants/action-types";

const initialState = {
    chatPayment : [],
    loading:true
}

export const chatPayment = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.CHAT_PAYMENT:
            return {
                ...state, 
                chatPayment: payload, 
                loading:false
            };
        case ActionTypes.CHAT_PAYMENT_ERROR:
        return{
            loading: false, 
            error: payload 
        }    
        default:
            return state;
    };
};