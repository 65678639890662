import { combineReducers } from "redux";
import { chatPayment } from "./chatpayment";
import { payeeDetails } from "./payeeDetails";
import { primaryAccount } from "./primaryAccount";
import { paySubmit } from "./paySubmit";

const reducers = combineReducers({
    primaryAccount : primaryAccount,
    chatPayment    : chatPayment,
    payeeDetails   : payeeDetails,
    paySubmit      : paySubmit
});

export default reducers;