import React,{useState,useEffect} from 'react';
import Header from "./../../components/Header";
import {Link} from "react-router-dom";
import { useDispatch , useSelector } from "react-redux";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { FaUserFriends,FaAngleLeft,FaChevronRight } from 'react-icons/fa';
import 'react-phone-input-2/lib/style.css';
import Button from 'react-bootstrap/Button';
import {firstCharacter,firstWordUpperCase} from "./../../helpers/helpers";
import { ActionTypes } from "./../../redux/contants/action-types";
import {setChatPayment} from './../../redux/actions/chatPayment';


export default function PrimaryAccount(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [amount,setAmount] = useState();
    const [description,setDescription] = useState();
    const primaryAccount = useSelector(state => state.primaryAccount.primaryAccount);
    const chatPayment = useSelector(state => state.chatPayment.chatPayment);
    
    useEffect(() =>{
        if(primaryAccount){
            localStorage.setItem("toAccountName",primaryAccount?.data?.name)
            localStorage.setItem("toAccountBank",primaryAccount?.data?.account)
            localStorage.setItem("toAccountYear",primaryAccount?.data?.year)
        }

    },[primaryAccount])
     
    useEffect(() => {
        const newData=chatPayment;
        if(newData.success){
            localStorage.setItem("fee",newData.data.fee)
            localStorage.setItem("plTransactionId",newData.data.plTransactionId)
            navigate('/toMobileChatPayment')
        }
    },[chatPayment]);

    const postPayPrimaryAccountDetails = async() =>{
        const payload = {
            "fromMobile" :localStorage.getItem('fromMobileNumber'),
            "toMobile": localStorage.getItem('toMobileNumber'),
            "fromCountryCode" : localStorage.getItem('fromCountryCode'),
            "toCountryCode" : localStorage.getItem('toCountryCode'),
            "amount": amount,
            "description" :description
        };
        const headers = { 
        'imei': 'fgfghghfa',
        'token': 'fdgfhhgha'
        };
        const response = await axios.post('http://52.215.106.23:8090/moneylinkdemo/api/chat/payment?source=mobile', payload , {headers})
        .then(response =>{
            dispatch(setChatPayment(response.data));
        })
                .catch((err) => {
                    dispatch( {
                        type: ActionTypes.CHAT_PAYMENT_ERROR,
                        payload: err,
                    })
                    console.log("Err", err);
                });
    }

    var a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
    var b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];

    const inWords = (num) => {
        if ((num = num.toString()).length > 9) return 'overflow';
        var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (n[1] !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
        str += (n[2] !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
        str += (n[3] !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
        str += (n[4] !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
        str += (n[5] !== 0) ? ((str !== '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Only ' : '';
        return str;
    }

    const updateAmount = (e) =>{
        if(e.target.value){
            setAmount(e.target.value)
            localStorage.setItem('amount',e.target.value);
            localStorage.setItem('amountInWords',inWords(e.target.value));
        }
    }

    const updateDescription = (e) =>{
        if(e.target.value){
            setDescription(e.target.value);
            localStorage.setItem('description',e.target.value)
        }
    }
    return (
        <div className='ToMobile'>
            <Header />
            <div className='payment-services'>
                Personal
                <div>
                    <FaUserFriends /> &nbsp; Payment Services
                </div>
            </div>
            <div className='backward-page'>
                <Link to="/toMobile">
                    <FaAngleLeft style={{height:"1.7rem",width:"1.7rem"}} />
                </Link>
            </div>
            <div className='PrimaryAccountWrapper'>
                <div className='PrimaryAccountParent'>
                    <div className='PrimaryAccountInputUserLink'>
                        <div className='PrimaryAccountSenderFirstLetter'>
                        N {/* We will have to change this code once we have login information */}
                        </div>
                        <div className='sendlink'>
                            <FaChevronRight style={{width:"25px",height:"25px"}} />
                        </div>
                        <div className='PrimaryAccountReceiverFirstLetter'>
                        {firstCharacter(primaryAccount?.data?.name)}
                        </div>
                    </div>
                    <div className='PrimaryAccountInputCard'>
                        <div className='cardName'>
                            Beneficiary Details
                        </div>
                        <div className='receiverName'>
                            {firstWordUpperCase(primaryAccount?.data?.name)}
                        </div>
                        <div className="receiverBankDetails">
                            <div className='receiverYear'>
                                {primaryAccount?.data?.year}
                            </div>
                            <div className='likedAccountDetails'>
                                {primaryAccount?.data?.account}
                            </div>
                        </div>
                    </div>
                    <div className='PrimaryAccountInputDetails'>
                        <div>
                            <div className='PrimaryAccountEnterAmount'>
                                Enter Amount
                            </div>
                            <div>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                <div className="input-group-text"><span>&#8358;</span></div>
                                </div>
                                <input type="number" className="form-control" id="inlineFormInputGroup" placeholder="Amount" onChange={(e)=>updateAmount(e)}/>
                            </div>
                            </div>
                        </div>
                        <div>
                            <div className='PrimaryAccountDescription'>
                            Description? (Optional)
                            </div>
                            <div>
                                <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Description" onChange={(e) => updateDescription(e)}/>
                            </div>
                        </div>  
                    </div>              
                </div>                
                {/* <Link to="/toMobileChatPayment" className='payButton'> */}
                    <Button variant="outline-primary" onClick={postPayPrimaryAccountDetails}>Pay</Button>
                {/* </Link> */}
            </div>
        </div>
      );
    
}