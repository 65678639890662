import React from 'react';
import {
  Link
} from "react-router-dom";
import tomobile from "./../components/icons/tomobile.png";


export default function ToMobile() {
  return(
    <div className='Home_Icons'>
      <Link to="/toMobile">
        <img alt="to Mobile" src={tomobile} style={{width:"3em",height:"3em"}}/>
        To Mobile
      </Link>
    </div>
  )
}