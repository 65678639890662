import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import Header from "./../../components/Header";
import { ActionTypes } from "./../../redux/contants/action-types";
import { FaUserFriends,FaAngleLeft,FaChevronRight } from 'react-icons/fa';
import 'react-phone-input-2/lib/style.css';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import {firstCharacter,firstWordUpperCase,numberWithCommas} from "./../../helpers/helpers";
import {setPayeeDetails} from "./../../redux/actions/payeeDetails";
import { setPaySubmit } from '../../redux/actions/paySubmit';

export default function ChatPayment() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const amount=localStorage.getItem("amount")
    const fee=localStorage.getItem("fee");
    const consolidatedValue=parseFloat(amount)+parseFloat(fee);
    const payeeDetails = useSelector(state => state.payeeDetails.payeeDetails);
    const paySubmit = useSelector(state => state.paySubmit.paySubmit);
    const getLinkedAccounts = payeeDetails?.data?.LinkedAccounts;

    useEffect(() => {
        const newData=paySubmit;
        if(newData.message === "SUCCESS"){
            navigate('/successPayment')
        }else if(newData.message === "UNSUCCESS"){
            navigate('/failurePayment')

        }
    },[paySubmit]);

    const postPaySubmit = async() =>{
        const payload = {
            "fromCustomer": localStorage.getItem('fromMobileNumber'),
            "toCustomer": localStorage.getItem('toMobileNumber'),
            "transactionAmount": consolidatedValue,
            "transactionType": "chatpayment",
            "plTransactionId": localStorage.getItem('plTransactionId')
        };
        const headers = { 
        'imei': 'test123',
        'source':'mobile',
        'token': 'eyJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJwYXlsaW5rIiwiYXVkIjoibml2aWFwcHN0YWNrQGdtYWlsLmNvbSx0ZXN0MTIzIiwiZXhwIjoxNTkwODE0NjQwLCJpYXQiOjE1ODk5NTA2NDAsImp0aSI6Ijg1NTk4ODI4NzAifQ.kK85WZ-cMq1wYlwsCXUsGeRqmt2BchR4BBZ64FrG8r_Tt27feGRunZmX5nxSb-Oz-FkEvq-p_SjTjxOw1B9H_IoqiWQkqgTKHRCTHaAuJyMvFxGjtmy07ZWc4N08kj4FaOZsNITRlUZdMNoiPQ0SXYK1Dn3_w3tjxIgP811zlnfPchNeBzo0X4Rr8P0U-jAWdy2ERwkbn_wbxN6oA8J6YgoDhfjUrEeM0aMdLI1AzmuGSr6YFkjORSIgdCp0-iw2EbV9F2VwzKKVoIF_f0luKEhP6GYkmcZh6oX1IiiMCGITDW6obazLw0q1iNIjLLwQF7jNcUJReutGlhvnZCxyEQ'
        };
        const response = await axios.post(`http://52.215.106.23:8090/moneylinkdemo/api/accounts/makepayment?accountnumber=`
        +localStorage.getItem('accountNumber')+`&bankname=`+localStorage.getItem('bankName')+`&apptype=CUSTOMER`, payload , {headers})
                    .then(response => {
                        dispatch(setPaySubmit(response.data));
                    })
                .catch((err) => {
                    dispatch( {
                        type: ActionTypes.CHAT_PAYMENT_ERROR,
                        payload: err,
                    })
                    console.log("Err", err);
                });
    }


    useEffect(() =>{
        const headers = { 
            'imei': 'test123',
            'chat':'yes',
            'source':'mobile',
            'token': 'eyJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJwYXlsaW5rIiwiYXVkIjoidGVzdEBnbWFpbC5jb20sdGVzdDEyMyIsImV4cCI6MTU5MDY2NjU2NCwiaWF0IjoxNTg5ODAyNTY0LCJqdGkiOiIzMTMxMzEzMTMzIn0.TttZr8Z8NkttPazyVQKMmBkqND22bRFLptGK47i_JD_TWN0_ezr6nn4f60OYJmAWKJbfZFwqyRook5whv3LGQTKQ_NSbBdTiS2UlpJKYDQcRzBnVr_XY-kvScGRS9hlwIyEMbGZI-YXrmowqw3c7wgoBqh58pevWYrrLKEs_aTQGZhjrAxJe98nKsCNtRTjJJVQk1F2Ex4erKivnpO61gijcQ2DU3CIbfabEMPq5lgJVDNpdDfxBOAow2ZmxyNehxq4gezOs9G3d1Kp3-R-9xcSftfX0fCJ7CUB9I12wvemZOKoOI4NfCkuvDS7TxzWfyWZTCZfD61o3rVJav6RYvw'
        };
        const response = axios.get(
                `http://52.215.106.23:8090/moneylinkdemo/api/accounts/payeedetails?payee=yes&payeemobile=`
                +localStorage.getItem('toMobileNumber')+`&mobile=`+localStorage.getItem('fromMobileNumber'), {headers})
                .then(response => {
                    dispatch(setPayeeDetails(response.data));
                })
                .catch((err) => {
                    dispatch( {
                        type: ActionTypes.PAYEE_DETAILS_ERROR,
                        payload: err,
                    })
                    console.log("Err", err);
                });
                
    },[]);
    const updateBankSelected = (e,accountNumber,bankName) =>{
        e.preventDefault();
        if(accountNumber){
            localStorage.setItem("accountNumber",accountNumber)
        }
        if(bankName){
            localStorage.setItem("bankName",bankName)
        }

    }
    return (
        <div className='ToMobile'>
            <Header />
            <div className='payment-services'>
                Personal
                <div>
                    <FaUserFriends /> &nbsp; Payment Request
                </div>
            </div>
            <div className='backward-page'>
                <Link to="/toMobile">
                    <FaAngleLeft style={{height:"1.7rem",width:"1.7rem"}} />
                </Link>
            </div>
            <div className='PrimaryAccountWrapper'>
                <div className='PrimaryAccountParent'>
                    <div className='PrimaryAccountInputUserLink'>
                        <div className='PrimaryAccountReceiverFirstLetter'>
                            A
                        </div>
                    </div>
                    <div className='PrimaryAccountInputCardWithOutStyle'>
                        <div className=''>
                            Beneficiary Details
                        </div>
                        <div className='receiverName25'>
                            {firstWordUpperCase(localStorage.getItem("toAccountName"))}
                        </div>
                        <div className="receiverBankDetailsColumn">
                            <div className='receiverYear'>
                                To bank account of {firstWordUpperCase(localStorage.getItem("toAccountName"))}
                            </div>
                            <div className='likedAccountDetails'>
                                {localStorage.getItem("toAccountBank")}
                            </div>
                        </div>
                    </div>
                <div className='amountWrapper'>
                    <div className='amountTitle'>
                        <div className='amountCode'>
                            <span>&#8358;</span>
                        </div>
                        <div className='amountActual'>
                            {numberWithCommas(localStorage.getItem("amount"))}
                        </div>
                    </div>
                    <div className='amountDetails'>
                        <div className='amountInWords'>
                            <div className='amountCodeFullForm'>
                                Naira:
                            </div>
                            <div className='amountInWordsText'>
                                {localStorage.getItem("amountInWords")}
                            </div>
                        </div>
                        <div className='amountDescription'>
                            <div className='amountDescriptionLabel'>
                                Description:
                            </div>
                            <div className='amountDescriptionValue'>
                             {localStorage.getItem("description")}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className='transactionProcessingFeeHeader'>
                    <div className='transactionProcessingFeeHeaderLable'>
                        Transaction Processing Fee:
                    </div>
                    <div className='transactionProcessingFeeHeaderValue'>
                        <span>&#8358;</span> {localStorage.getItem("fee")}
                    </div>
                </div>
                <div className='payUsing'>
                    <div className='payUsingWrapper'>
                        <div className='payUsingHeadline'>
                            Pay Using
                        </div>
                        <div className='payUsingLinkedAccounts'>
                            Linked Accounts
                        </div>
                    </div>
                </div>
                <div className='payLinkToText'>
                 Pay from any of your Bank Accounts linked to Montra App
                </div>
                <div className='linkedAccountList'>
                    <div className='linkedAccountListUl'>
                        {getLinkedAccounts?.map((item,index)=>{
                            if(item.primaryAccount==="YES"){
                                localStorage.setItem('accountNumber',item.accountNumber);
                                localStorage.setItem('bankName',item.bankName);
                            }
                        return(
                            <div className='nestedList'>
                                <div className='nestedAccountWrapper'>
                                    <div className='nestedAccountName' >
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) =>updateBankSelected(e,item.accountNumber,item.bankName)} checked={item.primaryAccount === "YES" ? true : false}/>
                                        </div>
                                        {item.maskedAccountNumber}
                                    </div>
                                    <div className='nextedAccountLogo' >
                                        <img src={item.bankImage} width="35px" height="35px"/>
                                    </div>
                                </div>
                                <div className='checkBalance'>
                                    Check Balance
                                </div>
                            </div>
                        )
                        })}


                    </div>
                </div>
                {/* <Link to="/successPayment"> */}

                    <Button variant="outline-primary" 
                    onClick={postPaySubmit}
                    >Pay <span>&#8358;</span> {numberWithCommas(consolidatedValue)}</Button>
                {/* </Link> */}
            </div>
        </div>
      );
    
}