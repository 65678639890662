import React from 'react';
import toself from "./../components/icons/toself.png";

export default function SelfTransfer() {
  return(
    <div className='Home_Icons'>
           <img alt="to Mobile" src={toself} style={{width:"3em",height:"3em"}}/>
      To Self
    </div>
  )
}