import React from 'react';
import ToMobile from "./../icons/ToMobile";
import QRScan from "./../icons/QRScan";
import BankTransfer from "./../icons/BankTransfer";
import SelfTransfer from "./../icons/SelfTransfer";
import { FaAngleRight } from 'react-icons/fa';

export default function MoneyTransfers() {
  return(
    <div className='MoneyTransfers'>
        <div className='MoneyTransfersHeading'>
            <div className='MoneyTransfersTitle'>
            Money Transfers
            </div>
            <div className='MoneyTransfersPermaLink'>
            Recent <FaAngleRight />
            </div>
        </div>
        <div className='MoneyTransfersIcons'>
            <ToMobile />
            <QRScan />
            <BankTransfer />
            <SelfTransfer />
        </div>
    </div>
  )
}