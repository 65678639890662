import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ContactNumber from './components/ToMobile/ContactNumber';
import PrimaryAccount from './components/ToMobile/PrimaryAccount';
import ChatPayment from './components/ToMobile/ChatPayment';
import SuccessPayment from './components/ToMobile/SuccessPayment';
import FailurePayment from './components/ToMobile/FailurePayment';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Routes>
            <Route path="/" element={<App props/>} />
            <Route path="/toMobile" element={<ContactNumber />} />
            <Route path="/toMobilePrimaryAccount" element={<PrimaryAccount props/>} />
            <Route path="/toMobileChatPayment" element={<ChatPayment />} />
            <Route path="/successPayment" element={<SuccessPayment />} />
            <Route path="/failurePayment" element={<FailurePayment />} />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
