import React from 'react';
import scanandpay from "./../components/icons/scanandpay.png";

export default function QRScan() {
  return(
    <div className='Home_Icons'>
     <img alt="to Mobile" src={scanandpay} style={{width:"3em",height:"3em"}}/>
      Scan & Pay
    </div>
  )
}