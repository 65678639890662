import { ActionTypes } from "./../contants/action-types";

const initialState = {
    paySubmit : [],
    loading:true
}

export const paySubmit = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.PAY_SUBMIT:
            return {
                ...state, 
                paySubmit: payload, 
                loading:false
            };
        case ActionTypes.PAYEE_SUBMIT_ERROR:
        return{
            loading: false, 
            error: payload 
        }    
        default:
            return state;
    };
};