import React,{useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaUserFriends,FaAngleLeft } from 'react-icons/fa';
import 'react-phone-input-2/lib/style.css';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {setPrimaryAccount} from './../../redux/actions/primaryAccount';
import { ActionTypes } from "./../../redux/contants/action-types";
import Header from "./../../components/Header";

export default function ContactNumber() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [fromMobileNumber,setFromMobileNumber] = useState('1111000000');  // This needs to be changed dynamically after we get the login done
    const [fromCountryCode,setFromCountryCode] = useState('234');  // This needs to be changed dynamically after we get the login done
    const [toMobileNumber,setToMobileNumber] = useState();
    const [toCountryCode,setToCountryCode] = useState();
    const primaryAccount = useSelector(state => state);
    const countryCodeUpdate = (toCountryCode) => {
        if(toCountryCode !== undefined && toCountryCode !== "" ){
            setToCountryCode(toCountryCode)
        }
    }

    const updateToMobileNumber = (e) =>{
        if(e.target.value){
            setToMobileNumber(e.target.value);
        }
    }

    const getPrimaryAccountDetails = async() =>{
        const payload = {
                            "mobile" : fromMobileNumber,
                            "countryCode" : toCountryCode,
                            "primaryAccountmobile" : toMobileNumber,
                            "primaryAccountCountryCode" : fromCountryCode
                        };
        const headers = { 
            'imei': 'fgfghghfa',
            'token': 'fdgfhhgha'
        };
        const response = await axios.post('http://52.215.106.23:8090/moneylinkdemo/api/primary/account?source=fdgfgf', payload , {headers})
                        .then(response =>{
                                dispatch(setPrimaryAccount(response.data));
                                navigate('/toMobilePrimaryAccount')
                                localStorage.setItem("toMobileNumber",toMobileNumber)
                                localStorage.setItem("fromMobileNumber",fromMobileNumber)
                                localStorage.setItem("toCountryCode",toCountryCode)
                                localStorage.setItem("fromCountryCode",fromCountryCode)
                        })
                        .catch((err) => {
                            dispatch( {
                                type: ActionTypes.PRIMARY_ACCOUNT_ERROR,
                                payload: err,
                            })
                            console.log("Err", err);
                        });
                        
    }

    return (
        <div className='ToMobile'>
            <Header />
            <div className='payment-services'>
                Personal
                <div>
                    <FaUserFriends /> &nbsp; Payment Request
                </div>
            </div>
            <div className='backward-page'>
                <Link to="/">
                    <FaAngleLeft style={{height:"1.7rem",width:"1.7rem"}} />
                </Link>
            </div>
            <div className='ToMobileWrapper'>

                <div className='ToMobileInput'>
                    <div>
                        Beneficiary Country
                    </div>
                    <PhoneInput
                        country={'us'}
                        enableSearch={true}
                        disableCountryCode={true}
                        placeholder={"Select Country"}
                        autoFormat={false}
                        className={"countryCode"}
                        onChange={(toCountryCode =>  countryCodeUpdate(toCountryCode))}
                    />
                </div>    
                <div className="input-group mobileNumber">
                    <div>
                        Enter 10 digit Mobile Number
                    </div>
                    {/* <span className="input-group-text">Mobile Number</span> */}
                    <input type="text" aria-label="First name" className="form-control contact-number" placeholder="Mobile Number" onChange={(e) => updateToMobileNumber(e)}/>
                </div>
                {/* <Link to="/toMobilePrimaryAccount"> */}
                    <Button variant="outline-primary" onClick={getPrimaryAccountDetails}>Next</Button>
                {/* </Link>             */}
            </div>
        </div>
      );
    
}