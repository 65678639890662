export function firstCharacter (character){
    if(character){
        return character.charAt(0).toUpperCase();
    }
}

export function firstWordUpperCase (words){
    if(words){
        return words.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
}
export function numberWithCommas (x){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

