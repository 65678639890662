import React,{useState} from 'react';
import { useSelector } from "react-redux";
import PhoneInput from 'react-phone-input-2';
import Header from "./../../components/Header";
import {Link} from "react-router-dom";
import { FaUserFriends,FaTimesCircle } from 'react-icons/fa';
import 'react-phone-input-2/lib/style.css';
import {firstCharacter,firstWordUpperCase,numberWithCommas} from "./../../helpers/helpers";
import Button from 'react-bootstrap/Button';


export default function FailurePayment() {
    const [fromMobileNumber,setFromMobileNumber] = useState();
    const [toMobileNumber,setToMobileNumber] = useState();
    const [countryCode,setCountryCode] = useState();
    const paySubmit = useSelector(state => state.paySubmit.paySubmit);
    const getErrorData = paySubmit?.errordata;
    // console.log(getErrorData)
    const mobileNumberUpdate = (fromMobileNumber) => {
        if(fromMobileNumber !== undefined && fromMobileNumber !== "" ){

            console.log(fromMobileNumber)
            setCountryCode(fromMobileNumber)
        }

    }

    return (
        <div className='ToMobile'>
            <Header />
            <div className='payment-services'>
                Personal
                <div>
                    <FaUserFriends /> &nbsp; Payment Request
                </div>
            </div>
            <div className='FailurePaymentWrapper'>
                <div className='SuccessPaymentParent'>
                    <div className='FailurePaymentInputUserLink'>
                        <div className='FailurePaymentReceiverFirstLetter'>
                        {firstCharacter(localStorage.getItem("toAccountName"))}
                        </div>
                        <div className='receiverNameWrapper'>
                            <div className='receiverName25'>
                            {firstWordUpperCase(localStorage.getItem("toAccountName"))}
                            </div>
                            <div className="receiverBankDetailsColumn">
                                <div className='likedAccountDetails'>
                                {localStorage.getItem("toAccountBank")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='SuccessPaymentInputCardWithOutStyle'>
                    </div>
                <div className='amountWrapper'>
                    <div className='amountTitle'>
                        <div className='amountCode'>
                            N
                        </div>
                        <div className='amountActual'>
                        {numberWithCommas(localStorage.getItem("amount"))}
                        </div>
                        <FaTimesCircle style={{width:"50px",height:"50px", color:"red",marginTop:"10px",marginLeft:"10px"}} />
                    </div>
                    <div className='amountInWords'>
                        <div className='timeDateFormat'>
                        {paySubmit?.data?.Transaction?.transactionDate}
                        </div>
                    </div>
                    <div className='amountDetails requestSuccessfull'>
                        
                        <div className='failureFullMessage'>
                            <div className='amountDescriptionValue failureMessage'>
                             Transaction Failed
                            </div>
                        </div>
                        <div className='failureMessageDetails'>
                            <div className='timeDateFormat'>
                                Transaction ID: {localStorage.getItem("plTransactionId")}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    <div className='commentNestedWrapper'>
                        <div className='commentReceiver errorWrapper'>
                            <div className='TranasactionFailureLable'>
                               Transaction Failure Reason:
                            </div>
                            <div className='TranasactionFailureMessage'>
                                <ul>
                                    {getErrorData.bankacctavailable === '' ? "":
                                        <li>
                                            {getErrorData.bankacctavailable}
                                        </li>}
                                    {getErrorData.fromCustomer === '' ? "":
                                        <li>
                                            {getErrorData.fromCustomer}
                                        </li>
                                    }
                                    {getErrorData.payPin === '' ? "":
                                        <li>
                                            {getErrorData.payPin}
                                        </li>
                                    }
                                    {getErrorData.servicePlan === '' ? "":
                                        <li>
                                            {getErrorData.servicePlan}
                                        </li>
                                    }   
                                    {getErrorData.serviceProvider === '' ? "":
                                        <li>
                                            {getErrorData.serviceProvider}
                                        </li>
                                    }
                                    {getErrorData.serviceType === '' ? "":
                                        <li>
                                            {getErrorData.serviceType}
                                        </li>
                                    }
                                    {getErrorData.toCustomer === '' ? "":
                                        <li>
                                            {getErrorData.toCustomer}
                                        </li>
                                    }
                                    {getErrorData.transactionAmount === '' ? "":
                                        <li>
                                            {getErrorData.transactionAmount}
                                        </li>
                                    }
                                    {getErrorData.transactionType === '' ? "":
                                        <li>
                                            {getErrorData.transactionType}
                                        </li>
                                    }
                                </ul>
                            </div>
                            <Link to="/toMobile">
                                <Button variant="outline-primary">Try Again</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='successPaymentHeader'>
                    <div className='successPaymentHeaderLable'>
                        Paid From:
                    </div>
                    <div className='successPaymentDetailsWrapper'>
                    <div className='successPaymentDetails'>
                        Access Bank PLC A/C ******7197
                    </div>
                    <div className='successPaymentDetails'>
                        {paySubmit?.data?.Transaction?.transactionDate}
                    </div>
                    <div className='successPaymentDetails'>
                        Transaction ID: {localStorage.getItem("plTransactionId")}
                    </div>
                </div>
                </div>
        </div>
      );
    
}