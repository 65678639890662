import React,{useState} from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FaUserCircle,FaRegBell } from 'react-icons/fa';
import {Link} from "react-router-dom";
import montraLogo from "./icons/montra-logo.png";
import alert from "./icons/alert.png";

export default function Header() {

    return (
        <div className='HeaderWrapper'>
            <div className='HeaderProfile'>
                <Link to="/">
                    <img src={alert} style={{width:"3em",height:"3em"}}/>
                </Link>
            </div>
            <div className="HeaderLogo">
            <Link to="/">
                <img src={montraLogo} />
            </Link>
            </div>
            <div className="HeaderNotifications">
                <img src={alert} style={{width:"3em",height:"3em"}}/>
            </div>                
        </div>
      );
    
}