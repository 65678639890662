import React from 'react';
import { useSelector } from "react-redux";
import Header from "./../../components/Header";
import moment from "moment";
import { FaUserFriends,FaCheck,FaRocketchat,FaThumbsUp } from 'react-icons/fa';
import 'react-phone-input-2/lib/style.css';
import {firstCharacter,firstWordUpperCase,numberWithCommas} from "./../../helpers/helpers";

export default function SuccessPayment() {
    const paySubmit = useSelector(state => state.paySubmit.paySubmit);

    return (
        <div className='ToMobile'>
            <Header />
            <div className='payment-services'>
                Personal
                <div>
                    <FaUserFriends /> &nbsp; Payment Request
                </div>
            </div>
            <div className='SuccessPaymentWrapper'>
                <div className='SuccessPaymentParent'>
                    <div className='SuccessPaymentInputUserLink'>
                        <div className='SuccessPaymentReceiverFirstLetter'>
                        {firstCharacter(localStorage.getItem("toAccountName"))}
                        </div>
                        <div className='receiverNameWrapper'>
                            <div className='receiverName25'>
                                {firstWordUpperCase(localStorage.getItem("toAccountName"))}
                            </div>
                            <div className="receiverBankDetailsColumn">
                                <div className='likedAccountDetails'>
                                {localStorage.getItem("toAccountBank")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='SuccessPaymentInputCardWithOutStyle'>
                         
                        
                    </div>
                <div className='amountWrapper'>
                    <div className='amountTitle'>
                        <div className='amountCode'>
                            N
                        </div>
                        <div className='amountActual'>
                            {numberWithCommas(localStorage.getItem("amount"))}
                        </div>
                        <FaCheck style={{width:"50px",height:"50px", color:"green",marginTop:"10px",marginLeft:"10px"}} />
                    </div>
                    <div className='amountInWords'>
                        <div className='timeDateFormat'>
                            17 September 2022, 09:38 AM
                        </div>
                    </div>
                    <div className='amountDetails requestSuccessfull'>
                        
                        <div className='successFullMessage'>
                            <div className='amountDescriptionValue'>
                             Request Successfull
                            </div>
                        </div>
                        <div className='successFullMessageDetails'>
                            <div className='timeDateFormat'>
                                Transaction ID: 
                            {localStorage.getItem("plTransactionId")}
                            </div>
                            <div className='timeDateFormat'>
                                Service Charges Debited : N
                            {localStorage.getItem("fee")}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className='refShareWrapper bottomSpace'>
                    <div className='share'>
                        Share
                    </div>
                    <div className='payAgain'>
                        Pay Again
                    </div>
                </div>
            </div>
            <div className='commentWrapper'>
                <div className='commentBorderd'>
                    <div className='commentNestedWrapper'>
                        <div className='commentIcon'>
                            <FaRocketchat style={{width:"35px",height:"35px"}}/>
                        </div>
                        <div className='commentReceiver'>
                            <div>
                                Message
                            </div>
                            <div>
                            {firstWordUpperCase(localStorage.getItem("toAccountName"))}
                            </div>
                        </div>
                    </div>
                    <div className='commentAutoSuggest'>
                        <div className='commentReceiver'>
                            <div className='dottedMessage'>
                            Money Sent <FaThumbsUp />
                            </div>
                            <div className='dottedMessage'>
                                Thanks
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='successPaymentHeader'>
                    <div className='successPaymentHeaderLable'>
                        Paid From:
                    </div>
                    <div className='successPaymentDetailsWrapper'>
                        <div className='successPaymentDetails'>
                            Access Bank PLC A/C ******7197
                        </div>
                        <div className='successPaymentDetails'>
                            {
                            
                                paySubmit?.data?.Transaction?.transactionDate
                                // ,'MMMM Do YYYY, h:mm:ss a'
                                }
                        </div>
                        <div className='successPaymentDetails'>
                            Transaction ID: 
                            {localStorage.getItem("plTransactionId")} 
                        </div>
                    </div>
            </div>
        </div>
      );
    
}