import './App.css';
import MoneyTransfers from "./components/MoneyTransfers";
import Header from "./components/Header";
import { FaUserFriends } from 'react-icons/fa';


function App() {
  return (
      <div className="App">
        <Header />
        <div className='payment-services'>
          <div>
            <FaUserFriends /> &nbsp; Payment Services
          </div>
        </div>
        <div className='mode-of-transaction'>
            <div className='Personal-mode-of-transaction'>
              Personal
            </div>
            <div className='Business-mode-of-transaction'>
              Business
            </div>    
        </div>
        <MoneyTransfers />
      </div>
  );
}

export default App;
